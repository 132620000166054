import { graphql, StaticQuery } from "gatsby"
import React, { useState } from "react"
import Fade from "react-reveal/Fade"
import Frame from "../components/common/frame"
import Hero from "../components/common/Hero/Hero"
import DoubleNavbar from "../components/DoubleNavbar"
import PopupIntegrationReq from "../components/integrations/popup-request-integration"
import "../styles/component/faq.scss"
import "../styles/component/pricing-faq.scss"
import "../styles/component/sidebar-small.scss"
import "../styles/pages/seo-post.scss"
import "../styles/templates/marketplace-single.scss"
import IntegrationsContent from "./integrations-content"
import SimilarIntegrations from "./similar-integrations"

function IntegrationSingle({ pageContext }) {
  let [requestForm, setRequestForm] = useState(false)

  const { seo } = pageContext

  return (
    <div className="integration-single">
      <StaticQuery
        query={graphql`
          query IntegrationSingle {
            SuperOps {
              pages(where: { title: "SEO Post" }) {
                title
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
              }
            }
            site {
              siteMetadata {
                url
                twitterHandle
              }
            }
          }
        `}
        render={data => (
          <>
            {data.SuperOps.pages.map(page => {
              const { title, navigationBlock } = page

              return (
                <>
                  <Frame seo={seo.seo} ogType="article" bigFooter>
                    <DoubleNavbar navType="double-nav" />

                    <PopupIntegrationReq
                      visibility={requestForm}
                      togglePopup={() => setRequestForm(!requestForm)}
                      ctaText={"SUBMIT YOUR PROFILE"}
                      title={"Tool Integration"}
                    />

                    <section>
                      <Hero
                        tagName="marketplace"
                        background="black"
                        tag={seo.footerLinkTitle.toLowerCase()}
                        heading={seo.title}
                        description={seo.subTitle}
                        primaryButtonText="INTEGRATE NOW"
                        primaryButtonLink="/signup"
                        primaryButtonClass="primary-new down-to-up-4 br47"
                        secondaryButtonText="BECOME A TECH PARTNER"
                        secondaryButtonLink="/partners/tech"
                        secondaryButtonClass="primary-new white-new down-to-up-4 br47"
                        image={seo.heroImage.url}
                        maxImageWidth="500px"
                        paddingBottom="70px"
                        newButton
                      />
                    </section>

                    <IntegrationsContent
                      seo={seo}
                      className="seo-main down-to-up-5"
                    />

                    <Fade duration={1000}>
                      <SimilarIntegrations
                        tag={seo.referenceTag.name}
                        title={seo.referenceTag.title}
                      />
                    </Fade>
                  </Frame>
                </>
              )
            })}
          </>
        )}
      />
    </div>
  )
}

export default IntegrationSingle
