import { graphql, Link, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CarouselComponent from "../components/common/carousel-component"
import SVGIcon from "../components/common/SVGIcon"

const SimilarIntegrations = props => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1.8,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 1.2,
    },
    mobile: {
      breakpoint: { max: 767, min: 500 },
      items: 1.2,
    },
    mobile1: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  }
  const [pathName, setPathName] = useState("")
  useEffect(() => {
    setPathName(window.location.pathname)
  }, [])
  const similarIntegrations = QuerySimilarIntegrations().SuperOps.seoPosts.filter(
    x => x.tag === props.tag && x.slug !== pathName.substring(1)
  )
  if (similarIntegrations.length) {
    return (
      <section
        className={`similar-integration position-relative ${
          similarIntegrations.length < 3 ? "hide-carousel-arrow" : ""
        }`}
      >
        <SVGIcon
          name="blurPiece"
          className="blur-piece left position-absolute z-1 d-dsk"
        />
        <SVGIcon
          name="blurPiece"
          className="blur-piece right position-absolute z-1 d-dsk"
        />
        <Container className="Layout-container position-relative">
          <Row className="integ-wrapper position-relative">
            <Col lg={4} className="left-col position-relative">
              <div className="altv3">
                <h2 className="heading mx-auto font-zodiak fw-bold mb30">
                  Similar integrations
                </h2>
              </div>
            </Col>
            <Col lg={8} className="right-col">
              <CarouselComponent
                swipeable
                responsive={responsive}
                customButtonNew
                status
                arrowClassName="btn-secondary-new"
              >
                {similarIntegrations.map(item => {
                  return (
                    <Link
                      to={`/${item.slug}`}
                      className="integ-box d-flex flex-column justify-content-between position-relative"
                    >
                      <SVGIcon
                        name="marketplace/plugInOut"
                        className="plug-in-out position-absolute"
                      />
                      <div className="img-wrapper mx-auto d-flex align-items-center">
                        <img src={item.logo.url} className="w-100 img" />
                      </div>
                      <div className="ref-tag text-center">
                        <p className="p16 m-0">{item.tag}</p>
                      </div>
                    </Link>
                  )
                })}
              </CarouselComponent>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
  return null
}
export default SimilarIntegrations

export function QuerySimilarIntegrations() {
  const data = useStaticQuery(graphql`
    query similarIntegrations {
      SuperOps {
        seoPosts(where: { pageName: Integrations, availability: available }) {
          title
          slug
          tag
          logo {
            url(transformation: { document: { output: { format: webp } } })
          }
        }
      }
    }
  `)
  return data
}
